
import Vue from 'vue'
import { addRow, checkCondition, getOptions, getImage, getSubformFieldPath, isNotNested,
         parseData, parseForm, removeRow } from '../../mixins/formData'

export default Vue.extend({
  name: 'CrudForm',

  data: () => {
    return {
      initCounter: 0,
      isLoading: true,
      errorMsg: '',
      errors: [],
      fetchActions: {
        // awards: 'fetchAward',
        awards: 'getAward',
        projects: 'getProject',
        sponsors: 'getSponsor',
        users: 'getUser',
      } as any,
      formData: {} as any,
      formNames: {
        awards: 'award_admin_crud',
        projects: 'projekt_admin_crud',
        sponsors: 'awardsponsor_admin_crud',
        users: 'user_admin_crud',
      } as any,
      formObj: {} as any,
      layouts: {},
      projectAwardIds: [] as any,
      saveActions: {
        awards: 'saveAward',
        projects: 'saveProjectCrud',
        sponsors: 'saveSponsor',
        users: 'saveUser',
      } as any,
      showContextSwitch: false,
      subformData: {} as any,
    }
  },

  computed: {
    appInfo () {
      return this.$store.state.appInfo
    },
    awardConf () {
      return this.$store.state.currentAwardConf
    },
    awards () {
      return this.$store.state.awards?.awards.filter((a: any) => this.projectAwardIds.includes(a.id))
    },
    backLink (): string {
      const { page, award } = this.crudListParams
      if (this.type === 'projects') {
        return '/admin/liste/' + this.type + '/' + award || this.currentAward.id + '/' + (page || 1)
      } else {
        return '/admin/liste/' + this.type
      }
    },
    baseUrl () {
      return this.$store.state.baseUrl
    },
    crudListParams (): any {
      return this.$store.state.crudListParams
    },
    currentAward (): any {
      return this.$store.state.currentAward
    },
    currentPart () {
      return this.$route.params.part ||this.$store.state.currentAwardConf?.forms.user_project_save.parts[0].key || 'part1'
    },
    formParts (): any {
      const { user, awards, awardSponsors } = this.$store.state.appInfo;
      switch (this.$route.params.type) {
        case 'awards':
          return awards.awardCrudConfiguration.forms.award_admin_crud.parts
        case 'sponsors':
          return awardSponsors.awardSponsorsConfiguration.forms.awardsponsor_admin_crud.parts
        case 'users':
          return user.userConfiguration.forms.user_admin_crud.parts
        default:
          return this.awardConf?.forms.projekt_admin_crud.parts
      }
    },
    id () {
      return this.$route.params.id
    },
    lastStep (): any {
      if (!this.formParts) return false
      return this.currentPart === this.formParts[this.formParts.length - 1].key
    },
    listAward (): any {
      return this.$store.state.listAward
    },
    object () {
      return this.$route.params.object
    },
    titles () {
      return this.$store.state.titles
    },
    triggerSaveForm () {
      return this.$store.state.triggerSaveForm
    },
    type (): string {
      return this.$route.params.type
    },
  },

  watch: {
    // formData (newData) {
    //   console.log('formData -> newData', newData);
    // },
    '$route' (to: any, from: any) {
      if (to.name === 'crud-bearbeiten' && to.path !== from.path) this.isLoading = true
      this.initObject()
    },
    triggerSaveForm (val) {
      // console.log('WATCH triggerSaveForm: ', val);
      if (val) {
        this.saveObject(true)
      }
    },
  },

  methods: {
    addRow (field: any, index: number) {
      const subformData = addRow(field, index, this.subformData)
      this.subformData = subformData
    },

    addRowMany (field: any, index: number) {
      const path = this.currentPart + '/' + field.name
      const formData = addRow(field, index, Object.assign({}, this.formData), path)
      this.formData = Object.assign({}, this.formData, formData)
    },

    addRowMany2 (field: any, f: any, i: number, j: number) {
      const path = this.currentPart + '/' + field.name + '[' + i + '].' + f.name
      const formData = addRow(field, j, Object.assign({}, this.formData), path)
      this.formData = Object.assign({}, this.formData, formData)
    },

    createNew (formData: any) {
      switch (this.type) {
        case 'awards':
          this.$store
            .dispatch('addAward', formData)
            .then(award => {
              if (award && award.id) {
                this.$store.commit('setProcessing', false)
                return this.$router.push('/admin/bearbeiten/awards/' + award.id)
              }
            }).catch((err: Error) => {
              this.handleErrors(err, false)
            })
          break
        case 'sponsors':
          this.$store
            .dispatch('addSponsor', formData)
            .then(sponsor => {
              if (sponsor && sponsor.id) {
                this.$store.commit('setProcessing', false)
                return this.$router.push('/admin/bearbeiten/sponsors/' + sponsor.id)
              }
            }).catch((err: Error) => {
              this.handleErrors(err, false)
            })
          break
        case 'users':
          this.$store
            .dispatch('addUser', formData)
            .then(user => {
              if (user && user.id) {
                this.$store.commit('setProcessing', false)
                return this.$router.push('/admin/bearbeiten/users/' + user.id)
              }
            }).catch((err: Error) => {
              this.handleErrors(err, false)
            })
          break
        default:
          this.$store
            .dispatch('addProject', formData)
            .then(project => {
              if (project && project.id) {
                this.$store.commit('setProcessing', false)
                return this.$router.push('/admin/bearbeiten/projects/' + project.id)
              }
            }).catch((err: Error) => {
              this.handleErrors(err, false)
            })
          break
      }
    },

    getDescription () {
      let res
      switch (this.$route.params.type) {
        case 'awards':
          res = this.formObj?.title
          break
        case 'sponsors':
          res = this.formObj?.name
          break
        case 'users':
          if (this.formObj.firstname && this.formObj.lastname) {
            res = this.formObj.firstname + ' ' + this.formObj.lastname
          } else {
            res = this.formObj.email
          }
          break
        default:
          res = this.formObj.titel
          break
      }
      return res
    },

    handleErrors (err: any, silent: boolean) {
      if (!silent && err.message.indexOf('errorObject') >= 0) {
        const errors = JSON.parse(err.message)
        this.errors = errors.errorObject
      } else  if (!silent && err.message.indexOf('Undefined index') >= 0) {
        let error: any = [{ field: 'email', error: 'email' }]
        this.errors = error
      } else {
        console.error(err)
      }
      if (!silent) {
        this.$store.commit('setAlert', {
          type: 'error',
          message: 'Fehler beim Speichern! Bitte prüfen Sie die Eingaben.'
        })
      }
      this.$store.commit('setProcessing', false)
      this.$store.commit('triggerSaveForm', false)
      this.initCounter++
    },

    initObject () {
      console.log('initObject');
      if (this.id === 'neu') {
        this.isLoading = false
        return
      }
      this.$store
        .dispatch(this.fetchActions[this.type], this.$route.params.id)
        .then((obj) => {
          console.log(this.fetchActions[this.type] + ': ', obj);
          if (this.type === 'projects') {
            if (obj.projectDetails && this.currentAward) {
              const { awardType, year } = this.currentAward
              const pd = obj.projectDetails.filter((o: any) => o.awardType == awardType && o.year == year)
              if (pd && pd[0]) {
                const pd0 = Object.entries(pd[0])
                pd0.forEach(o => {
                  if (typeof o[1] !== 'undefined' && typeof obj[o[0]] === 'undefined') {
                    obj[o[0]] = o[1]
                  }
                })
              }
            } else if (obj.projectDetail) {
              this.projectAwardIds = obj.projectDetails.map((pd: any) => pd.awardId)
            }
          }

          if (!this.showContextSwitch) {
            const { formData, subformData, layouts, reloadTriggers } = this.parseData(this.formParts, obj, this.formData)
            this.$store.commit('setReloadTriggers', reloadTriggers)
            this.formObj = obj
            this.formData = Object.assign({}, this.formData, formData)
            this.subformData = Object.assign({}, this.subformData, subformData)
            this.layouts = Object.assign({}, this.layouts, layouts)
          }
          this.isLoading = false
          this.$store.commit('triggerSaveForm', false)
          this.initCounter++
        }).catch((err: Error) => {
          console.error(err)
        })
    },

    remoteFunction (opt: any) {
      this.$store.commit('updateRemoteFunctionValue', opt);
      if (this.subformData.remoteFunction && this.subformData.remoteFunction[opt.name]) {
        let obj = this.subformData.remoteFunction[opt.name]
        obj[opt.path] = opt.value
        // console.log('remoteFunction obj: ', obj);
        const { awardType, year } = this.currentAward
        const formName = this.formNames[this.type]
        let data: any = {
          data: obj,
          formName,
          awardType,
          year,
          name: opt.name,
          id: this.id,
          part: this.currentPart,
        }
        this.$store.dispatch('postRemoteFunction', data).then((res: any) => {
          // console.log('postRemoteFunction RESPONSE: ', res)
          if (res && res.output) {
            for (let k in res.output) {
              this.formData[this.currentPart + '/' + k] = res.output[k]
            }
            this.formData = Object.assign({}, this.formData)
          }
        })
      }
    },

    removeRow (field: any, index: number) {
      const { formData, subformData } = removeRow(field, index, this.formData, this.subformData)
      this.formData = formData
      this.subformData = subformData
    },

    removeRowMany (field: any, index: number) {
      const path = this.currentPart + '/' + field.name
      const { formData } = removeRow(field, index, Object.assign({}, this.formData), {}, path)
      this.formData = formData
    },

    removeRowMany2 (field: any, f: any, i: number, j: number) {
      const path = this.currentPart + '/' + field.name + '[' + i + '].' + f.name
      const { formData } = removeRow(field, j, Object.assign({}, this.formData), {}, path)
      this.formData = formData
    },

    replaceN (str: string, num: any) {
      return str.replace('%n%', num)
    },

    async saveObject (silent: boolean) {
      silent = silent === true
      // prepare data
      this.$store.commit('setProcessing', true)
      const { awardType, year } = this.currentAward || {}
      const formName = this.formNames[this.type]
      let formData = this.parseForm(this.formParts, this.formData)[this.currentPart]
      let data: any = {
        formData,
        formName,
        awardType,
        year,
        id: this.id,
        part: this.currentPart,
        silent,
      }

      // POST create
      if (this.id === 'neu') {
        return this.createNew(formData);
      }
      // POST save
      this.errors = [];
      this.$store.dispatch(this.saveActions[this.type], data).then((obj) => {
        console.log(this.saveActions[this.type] + ': ', obj);
        if (!silent) {
          this.$store.commit('setAlert', {
            type: 'info',
            message: this.titles[this.type] + ' Schritt ' + this.currentPart.replace('part', '') + ' wurde erfolgreich gespeichert.'
          })
        }

        if (this.id === 'neu') {
          // forward to new obj
          this.$router.push({name: 'crud-bearbeiten', params: { id: this.id, part: this.currentPart, object: this.object }})
        } else {
          // refresh with returned obj
          this.initObject()
          if (!silent) {
            if (!this.lastStep) {
              const stepNum = parseInt(this.currentPart.substr(this.currentPart.length - 1), 10)
              const part = this.currentPart.replace(stepNum, stepNum + 1)
              this.$router.push({name: 'crud-bearbeiten', params: { id: this.id, part, object: this.object }})
            } else {
              this.$router.push(this.backLink) // restore previously active list on return
            }
            window.scrollTo({top: 0, behavior: 'smooth'})
          }
        }
        this.$store.commit('setProcessing', false)
        this.initCounter++
      }).catch((err: Error) => {
        this.handleErrors(err, silent)
      })
      return false
    },

    switchContext (val: any) {
      if (!val) {
        return console.error('must select context')
      }

      let res = this.awards?.filter((aw: any) => aw.id == val)[0]
      if (res) {
        this.showContextSwitch = false
        this.$store.commit('setCurrentAward', res)
      }
      return this.initObject()
    },

    checkCondition,
    getImage,
    getOptions,
    getSubformFieldPath,
    isNotNested,
    parseData,
    parseForm,
  },

  mounted () {
    if (this.type === 'projects' && !this.currentAward) {
      this.showContextSwitch = true
    }
    this.initObject()
  },

  destroyed () {
    this.$store.commit('setDialog', null)
  },
})
